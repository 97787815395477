import tcbanner from "../../media/images/tandcOriginal.jpg";
import "./tandc.css";

const TermsAndConditions = () => {
  return (
    <>
      {/* <div style={{width: "95%", margin: "-110px auto", minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 10"}}>
        <div style={{ flex: "0.8", paddingLeft:"2rem", paddingRight: "4rem", boxShadow: "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset"}}>
            <p style={{marginTop: "2rem", fontSize: "1.6rem", lineHeight: "1.4", fontFamily:"sans-serif", alignItems:"flex-start", marginBottom: "2rem", color:"#475569"}}>
            Welcome to our website. This website is offered to you, the customer, in good faith for personal perusal only on the condition that your access should not impair the performance, corrupt the contents or reduce the overall functionality of this website. The content of the pages of this website is for your general information and use only. It is subject to change without notice. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law. Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be absolutely your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.
            </p> 
        </div>  
        <div style={{ flex: "2", marginLeft:"2rem"}}>
            <img
              src={tcbanner}
              alt="About Us Banner"
              style={{width: "100%", objectFit: "cover"}}
            /> 
        </div>        
        </div> */}
      <div
        style={{
          backgroundColor: "#f5f5f5",
          //   marginTop: "-50px",
          //   marginBottom: "-50px",
        }}
      >
        <div
          style={{
            width: "70%",
            margin: " auto",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "35px 0",
          }}
        >
          <div className="banner">
            {/* <img
              src={tcbanner}
              alt="T&C Banner"
              style={{ width: "90%", marginTop: "80px" }}
            /> */}
            <h1
              style={{ fontSize: "40px", fontWeight: "600", margin: "20px 0" }}
            >
              Terms & Conditions
            </h1>
            {/* <p
              style={{
                fontSize: "1.6rem",
                lineHeight: "1.8",
                textAlign: "justify",
                fontWeight:"500",
              }}
            >
              These Terms and Conditions, along with privacy policy or other
              terms (“Terms”) constitute a binding agreement by and between WB
              HOTELS PRIVATE LIMITED, ( “Website Owner” or “we” or “us” or
              “our”) and you (“you” or “your”) and relate to your use of our
              website, goods (as applicable) or services (as applicable)
              (collectively, “Services”).<br></br>
              <br></br> By using our website and availing the Services, you
              agree that you have read and accepted these Terms (including the
              Privacy Policy). We reserve the right to modify these Terms at any
              time and without assigning any reason. It is your responsibility
              to periodically review these Terms to stay informed of updates.
            </p> */}
          </div>
          <div
            className="content-tc"
            style={{ marginTop: "5px", marginBottom: "2rem" }}
          >
            {/* <p style={{fontWeight: "bold", fontSize: "2.5rem", textAlign: "center", marginTop: "1rem", fontFamily:"sans-serif"}}>About Us</p> */}
            <p
              style={{
                marginTop: "1.8rem",
                fontSize: "1.6rem",
                lineHeight: "26px",
                fontFamily: "sans-serif",
                textAlign: "justify",
                fontWeight: "500",

                // marginLeft: "180px",
              }}
            >
              {/* The use of this website or availing of our Services is subject to
              the following terms of use: <br />
              <br /> */}
              <ul>
                <li>
                  During the stay, terms & conditions to be followed as per the
                  house rule policy.
                </li>
                <li>
                  The content and information displayed on this web site
                  (“www.wbhotels.in”) is the property of WB Hotels & Resorts and
                  is collectively referred to as (“WB Hotels (WB HOTELS PRIVATE
                  LIMITED) Information”, ” WB”, “we”, “our” or “us”). The
                  downloading, reproduction, or retransmission of WB
                  Information, other than for non-commercial individual use, is
                  strictly prohibited.
                </li>
                <li>
                  By using this website you are accepting all the terms and
                  conditions set forth below. If you do not agree to each and
                  all of these terms and conditions please do not use the site.
                  We reserve the right, at our discretion, to change and/or
                  modify portions of these terms at any time without notice and
                  unless otherwise indicated, such changes will become effective
                  immediately; therefore, please check these terms periodically
                  for changes.
                </li>
                <li>
                  WB internet sites may contain reference trademarks, patents,
                  copyrighted materials, trade secrets, technologies, products,
                  processes or other proprietary rights of WB. No license in any
                  such trademarks, patents, copyrighted materials, trade
                  secrets, technologies, products, processes and other
                  proprietary rights of WB and/or other parties is granted to or
                  conferred upon you.
                </li>
                <li>
                  You agree that you will not use any device, software or other
                  instrumentality to attempt to interfere with the proper
                  working of our site, and that you will not take any action
                  that imposes an unreasonable or disproportionately large load
                  on our infrastructure. In addition, you agree that you will
                  not use any robot, spider, other automatic device, or manual
                  process to monitor or copy our web pages or the content
                  contained herein, without the prior express consent from an
                  authorized WB personnel (such consent is deemed given for
                  standard search engine technology employed by Internet search
                  web sites to direct Internet users to this site).
                </li>
                <li>
                  You agree that you will make only legitimate reservations in
                  good faith for use by you and your invited guests only, and
                  not for other purposes, including without limitation,
                  reselling, impermissibly assigning or posting on third party
                  websites, or making speculative, false or fraudulent
                  reservations, or any reservation in anticipation of demand.
                </li>
                <li>
                  This site may provide you with the ability to use usernames,
                  passwords, or other codes or devices to gain access to
                  restricted portions of this site (“access codes”). The content
                  contained in such restricted areas is confidential to WB, and
                  is provided to you for your business use only, which includes
                  reviewing commission status and statements, and enrolling in
                  our Electronic Funds Transfer program as described below. We
                  reserve the right to prohibit the use of such access codes on
                  your behalf by third parties where we determine that such use
                  interferes with our site’s operation or results in commercial
                  benefits or other entities to our detriment.
                </li>
                <li>
                  To the maximum extent permitted by law, all WB jurisdictions
                  are “as is” without warranty of any kind, either express or
                  implied, including, but not limited to, the implied warranties
                  of merchantability, fitness for a particular purpose, or
                  non-infringement. Some jurisdictions do not allow the
                  exclusion of implied warranties, so the above exclusion may
                  not apply to you.
                </li>
                <li>
                  To the maximum extent permitted by law, we, other members of
                  our group of companies and third parties connected to us
                  hereby expressly exclude any liability for any direct,
                  indirect or consequential loss or damage incurred by any user
                  in connection with our site or in connection with the use,
                  inability to use, or results of the use of our site, any
                  websites linked to it and any materials posted on it,
                  including, without limitation any liability for loss of income
                  or revenue; loss of business; loss of profits or contracts;
                  loss of anticipated savings; loss of data; loss of goodwill;
                  wasted management or office time; and for any other loss or
                  damage of any kind, however arising and whether caused by tort
                  (including negligence), breach of contract or otherwise, even
                  if foreseeable, provided that this condition shall not prevent
                  claims for loss of or damage to your tangible property or any
                  other claims for direct financial loss that are not excluded
                  by any of the categories set out above.
                </li>
                <li>
                  With respect to all communications you make to WB regarding
                  information including but not limited to feedback, questions,
                  comments, suggestions and the like: (a) you shall have no
                  right of confidentiality in your communications and WB shall
                  have no obligation to protect your communications from
                  disclosure; (b)WB shall be free to reproduce, use, disclose
                  and distribute your communications to others without
                  limitation; and (c)WB shall be free to use any ideas,
                  concepts, know-how, content or techniques contained in your
                  communications for any purpose whatsoever, including but not
                  limited to the development, production and marketing of
                  products and services that incorporate such information. The
                  above is limited only by our commitment and obligations
                  pertaining to your personal information.
                </li>
                <li>
                  WB Hotels Information may contain technical inaccuracies and
                  typographical errors, including but not limited to
                  inaccuracies relating to pricing or availability applicable to
                  your transaction.WB shall not assume responsibility or
                  liability for any such inaccuracies, errors or omissions, and
                  shall have no obligation to honour reservations or information
                  affected by such inaccuracies.WB reserves the right to make
                  changes, corrections, cancellations and/or improvements to WB
                  hotel Information, and to the products and programs described
                  in such information, at any time without notice, including
                  after confirmation of a transaction.WB sites contain
                  information on WB products and services, not all of which are
                  available in every location. A reference to a WB product or
                  service on a WB Internet site does not imply that such product
                  or service is or will be available in your location.
                </li>
                <li>
                  If you choose to leave this WB site via links to other non-WB
                  sites, including those of advertisers,WB is not responsible
                  for the privacy policies of those sites or the cookies those
                  sites use. In addition, because WB has no control over such
                  sites and resources, you acknowledge and agree that WB is not
                  responsible for the availability of such external sites or
                  resources, and does not endorse or is not responsible or
                  liable for any content, advertising, products, or other
                  materials on or available from such sites or resources.
                </li>
                <li>
                  We may revise these terms of use at any time by amending this
                  page. You are expected to check this page from time to time to
                  take notice of any changes we made, as they are binding on
                  you. Some of the provisions contained in these terms of use
                  may also be superseded by provisions or notices published
                  elsewhere on our site.
                </li>
                <li>
                  If you have questions about these Terms of Use please send an
                  email to booking@wbhotels.in
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default TermsAndConditions;
