import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import "./autoStyle.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { LoginContext } from "../../Contexts/LoginContext";
import { Tooltip } from "@mui/material";
import { fetchHotelsBE } from "../../apis/searchHotel.api";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleMaps(props) {
  const { onPlaceSelected } = props;
  const { autoCompleteSelectedValue, setAutoCompleteSelectedValue } =
    React.useContext(LoginContext);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [lat_Lng, setLatLng] = React.useState({});
  const loaded = React.useRef(false);

  // Use a ref to store the dynamic hotel list fetched via API
  const hotelListRef = React.useRef([]);

  React.useEffect(() => {
    fetchHotelsBE({ searchText: inputValue }).then((data) => {
      hotelListRef.current = data;
    });
  }, [inputValue]);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    onPlaceSelected(value, inputValue || autoCompleteSelectedValue, lat_Lng);
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      { input: inputValue, componentRestrictions: { country: "IN" } },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          // Prioritize hotelListRef options first
          const hotelOptions = hotelListRef.current.filter((hotel) =>
            hotel.name.toLowerCase().includes(inputValue.toLowerCase())
          );

          if (results) {
            // Filter out hotels from Google results
            const filteredResults = results.filter(
              (result) =>
                !result.types.includes("lodging") &&
                !result.types.includes("hotel")
            );
            newOptions = [...hotelOptions, ...filteredResults];
          } else {
            newOptions = hotelOptions;
          }

          // Remove duplicates
          const uniqueOptions = Array.from(
            new Set(
              newOptions.map((option) => option.description || option.name)
            )
          ).map((descriptionOrName) => {
            return (
              newOptions.find(
                (option) => option.description === descriptionOrName
              ) ||
              hotelOptions.find((hotel) => hotel.name === descriptionOrName)
            );
          });

          setOptions(uniqueOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, lat_Lng]);

  function setSelectedValue(selectedValue) {
    setValue(selectedValue);
    if (selectedValue) {
      const selectedHotel = hotelListRef.current.find(
        (hotel) => hotel.name === selectedValue.name
      );
      if (selectedHotel) {
        setLatLng({ lat: selectedHotel.lat, lng: selectedHotel.lng });
      } else {
        getGeocode({ address: selectedValue.description })
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }) => setLatLng({ lat, lng }))
          .catch((error) => console.log("😱 Error: ", error));
      }
    }
  }

  function handleSelect(selectedValue) {
    return () => {
      setSelectedValue(selectedValue);
    };
  }

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description || option.name
      }
      style={{ fontWeight: "bold", color: "red" }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setSelectedValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setAutoCompleteSelectedValue(newInputValue);
      }}
      renderInput={(params) => (
        <Tooltip
          title={autoCompleteSelectedValue}
          sx={{ fontWeight: "bold", color: "red" }}
        >
          <TextField
            {...params}
            label="Search by city, hotel or neighbourhood"
            variant="standard"
          />
        </Tooltip>
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting?.main_text_matched_substrings || [];
        const parts = parse(
          option.structured_formatting?.main_text || option.name,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center" onClick={handleSelect(option)}>
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: "text.secondary", mr: 2 }}
                />
              </Grid>
              <Grid item md>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.description || option.address}{" "}
                  {/* Display address here */}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
