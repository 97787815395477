import React from "react";
import YouTube from "react-youtube";

const YoutubeWb = () => {
  const onReadyPausePlay = (e) => {
    e.target.pauseVideo();
  };
  const opts = {
    height: "350",
    width: "550",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  return (

    <>
    <div className="login-content-div">
    <h2 >
    WB Hotels across 200 cities, 2 countries
    </h2>
    <h1>
    WB Hotels your accommodations buddy
    </h1>
    <p>
    Sign up with your phone number and get exclusive access to
 discounts and savings on WB HOTELS stays and with our many
 travel partners.
    </p>
   </div>

    <div>
      <YouTube videoId="Y8ecqYujvFw" opts={opts} onReady={onReadyPausePlay} />
    </div>
    </>
  );
};

export default YoutubeWb;
