import poojamai from "../../media/images/Poojamai-01.png";
import shweta from "../../media/images/Shweta-01.png";
import prashant from "../../media/images/Prashant P-01.png";
import asad from "../../media/images/Asad.png";
import aman from "../../media/images/Aman G-01.png";
import chandan from "../../media/images/Chandan p-01.png";
import george from "../../media/images/George N-01.png";
import hoshang from "../../media/images/Hoshang Varaiva-01.png";
import irfan from "../../media/images/irfan.jpeg";
import sudeep from "../../media/images/sudeep.jpg";

export const cardData = [
  {
    name: "Chandan Pandey",
    designation: "Co-founder & CEO",
    linkedin: "https://www.linkedin.com/in/chandan-pandey-035b8019/",
    image: chandan,
  },
  {
    name: "Aman Gupta",
    designation: "Founder",
    linkedin: "https://www.linkedin.com/in/aman-gupta-31b70561/",
    image: aman,
  },

  {
    name: "George Nathaniel",
    designation: "APAC Sales",
    linkedin: "https://www.linkedin.com/in/georgenathaniel/",
    image: george,
  },
  {
    name: "Prashant Pandey",
    designation: "Chief Human Resource Manager",
    linkedin: "https://www.linkedin.com/in/peekay/",
    image: prashant,
  },
  {
    name: "Shweta Bajaj",
    designation: "Customer Success Manager",
    linkedin: "https://www.linkedin.com/in/shweta-bajaj-88a607195/",
    image: shweta,
  },
  {
    name: "Poojamai",
    designation: "Full Stack Developer",
    college: "B-Tech  IIT Bombay",
    linkedin: "https://www.linkedin.com/in/poojamai-daravath-20188a1ab/",
    image: poojamai,
  },
  {
    name: "Sudeep Gowda",
    designation: "Full Stack Developer",
    college: "B-Tech  IIT Bombay",
    linkedin: "https://www.linkedin.com/in/sudeep-gowda-358b801b6/",
    image: sudeep,
  },
  {
    name: "Asad Ayyubi",
    designation: "Full Stack Developer",
    college: "B.E (CSE)",
    linkedin: "https://www.linkedin.com/in/asad-ayyubi-54b46a183/",
    image: asad,
  },
  
  
  {
    name: "Mohammad Irfan Parwez",
    designation: "Full Stack Developer",
    college: "B-Tech",
    linkedin: "https://www.linkedin.com/in/mohammad-irfan-parwez-910553231/",
    image: irfan,
  },
 
];
